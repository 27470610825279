<template>
  <div class="form-group col-lg">
    <label class="form-label">
      {{ $t("customer.customerNumber") }}
     <!--  <span v-if="valid === false">{{ $t("customer.isInUse") }}</span> -->
    </label>
    <input
      maxlength="11"
      class="form-control form-control-sm customer-number-input"
      type="number"
      :placeholder="$t('customer.customerNumber')"
      v-model="input"
      v-bind:class="{'':valid === null,
                      'is-valid':valid === true,
                      'is-invalid':valid === false}"
    >

  </div>
</template>

<script>
export default {
  props: ["value", "valid", "token", "orig"],
  data() {
    return {
      searchTimeout: null,
      searchText: null,
      re: /^[1-9]+[0-9]*/
    };
  },
  computed: {
    input: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    }
  },
  methods: {
    debounceSearch: function(val) {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      var that = this;
      this.searchTimeout = setTimeout(function() {
        that.$emit("input", val);
      }, 500);
    }
  },
  watch: {
    value: function(val) {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      let that = this;
      that.$emit("validate", null);
      this.searchTimeout = setTimeout(function() {
        if (val) {
          if (val == that.orig) {
            that.$emit("validate", true);
          } else if (Number(val) <= 0 || !that.re.test(val)) {
            that.$emit("validate", false);
          } else {
            that.$store
              .dispatch("customer/checkCustomerNumber", {
                number: val,
                token: that.token
              })
              .then(data => {
                if (data.hasOwnProperty("data")) {
                  that.$emit("validate", data.data);
                }
              })
              .catch(error => {
                console.log(error);
              });
          }
        }
      }, 500);
    }
  }
};
</script>

<style scoped>
.red {
  color: red;
}
.customer-number-input {
  border-radius: 0;
  box-shadow: 1px 1px 1px 1px black!important;
  height: 1.5em;
}
@media screen and (max-width: 376px) {
 .customer-number-input {
    border-radius: 0;
    box-shadow: none !important;
  }
}
</style>

