<template>
  <div class="form-section">
    <div class="col">
      <div @click="$emit('hide', !display)" class="row form-section-head">
        <h4 style="width:auto;" class>{{ $t("customer." + addressType) }}</h4>
        <i v-if="!display" class="fa fa-plus"></i>
        <i v-if="display" class="fa fa-minus"></i>
      </div>
      <!--               <div class="row">
                <div style="position:relative" class="form-group">
                  <label class="form-label">{{ $t("customer.addressType") }}</label>
                  <select class="form-control form-control-sm" v-model="addressType">
                    <option
                      v-for="(a, index) in addressTypes"
                      :key="index"
                      :value="a"
                    >{{ $t("customer." + a)}}</option>
                  </select>
                </div>
      </div>-->
      <div v-bind:class="{'hide':!display}" class="row">
        <purchase-invoice-form-field
          v-model="name"
          :valid="nameIsValid"
          v-on:validate="nameIsValid = $event"
          :validator="simpleValidation"
          :label="$t('invoiceForm.name')"
        />
        <customer-bid-input
          v-if="addressType === 'officialAddress' || addressType === 'billingAddress'"
          v-model="businessId"
          :valid="businessIdIsValid"
          v-on:focused="$emit('focused')"
          v-on:unfocused="$emit('unfocused')"
          v-on:validate="businessIdIsValid = $event"
          :validator="businessIdValidator"
          :label="$t('invoiceForm.businessId')"
        />
      </div>
      <div v-if="display" class="row">
        <purchase-invoice-form-field
          v-model="street"
          :valid="streetIsValid"
          v-on:validate="streetIsValid = $event"
          :validator="simpleValidation"
          :label="$t('invoiceForm.address')"
        />
        <purchase-invoice-form-field
          v-model="postal"
          :valid="postalIsValid"
          v-on:validate="postalIsValid = $event"
          :validator="simpleValidation"
          :label="$t('invoiceForm.postal')"
        />
      </div>
      <div v-if="display" class="row mb-2">
        <purchase-invoice-form-field
          v-model="zipCode"
          :valid="zipCodeIsValid"
          v-on:validate="zipCodeIsValid = $event"
          :validator="simpleValidation"
          :label="$t('invoiceForm.zipCode')"
        />
        <customer-country-input
          :addrType="addressType"
          :valid="countryIsValid"
          v-on:validate="countryIsValid = $event"
          v-on:country="country = $event"
          :country="country"
          :countries="countries"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PurchaseInvoiceFormField from "@/components/purchaseInvoice/PurchaseInvoiceFormField";
import CustomerCountryInput from "@/components/customer/CustomerCountryInput";
import CustomerBidInput from "@/components/customer/CustomerBidInput";
import {
  simpleValidation,
  validateYtunnus,
  validateOrgNum,
  validateMinEight
} from "@/utils";
import { codes } from "@/assets/countrycodes";

export default {
  props: ["address", "addressType", "validations", "display"],
  components: {
    PurchaseInvoiceFormField,
    CustomerCountryInput,
    CustomerBidInput
  },
  data() {
    return {
      simpleValidation,
      validateYtunnus,
      validateOrgNum,
      validateMinEight
    };
  },
  watch: {
    businessIdValidator: function(val) {
      if (this.addressType !== "deliveryAddress") {
        this.businessIdIsValid = val(this.businessId);
      }
    }
  },
  computed: {
    businessIdValidator: function() {
      switch (this.businessIdType) {
        case "Ytunnus":
          return this.validateYtunnus;
          break;
        case "orgNumber":
          return this.validateOrgNum;
          break;
        default:
          return this.validateMinEight;
          break;
      }
    },
    businessIdType: function() {
      if (this.country.value) {
        switch (this.country.value) {
          case "FI":
            return "Ytunnus";
            break;
          case "SE":
            return "orgNumber";
            break;
          default:
            return "businessId";
            break;
        }
      } else {
        return "businessId";
      }
    },
    countries: function() {
      return codes[this.$store.getters["invoice/getField"]("userData.locale")];
    },
    name: {
      set: function(val) {
        this.$emit("update", {
          field: "name",
          adressType: this.addressType,
          value: val
        });
      },
      get: function() {
        return this.address.name;
      }
    },
    businessId: {
      set: function(val) {
        this.$emit("update", {
          field: "businessId",
          adressType: this.addressType,
          value: val
        });
      },
      get: function() {
        return this.address.businessId;
      }
    },
    postal: {
      set: function(val) {
        this.$emit("update", {
          field: "postal",
          adressType: this.addressType,
          value: val
        });
      },
      get: function() {
        return this.address.postal;
      }
    },
    zipCode: {
      set: function(val) {
        this.$emit("update", {
          field: "zipCode",
          adressType: this.addressType,
          value: val
        });
      },
      get: function() {
        return this.address.zipCode;
      }
    },
    country: {
      set: function(val) {
        this.$emit("update", {
          field: "country",
          adressType: this.addressType,
          value: val
        });
      },
      get: function() {
        return this.address.country;
      }
    },
    street: {
      set: function(val) {
        this.$emit("update", {
          field: "address",
          adressType: this.addressType,
          value: val
        });
      },
      get: function() {
        return this.address.address;
      }
    },
    streetIsValid: {
      set: function(val) {
        this.$emit("validate", {
          field: "address",
          adressType: this.addressType,
          value: val
        });
      },
      get: function() {
        return this.validations.address;
      }
    },
    nameIsValid: {
      set: function(val) {
        this.$emit("validate", {
          field: "name",
          adressType: this.addressType,
          value: val
        });
      },
      get: function() {
        return this.validations.name;
      }
    },
    businessIdIsValid: {
      set: function(val) {
        this.$emit("validate", {
          field: "businessId",
          adressType: this.addressType,
          value: val
        });
      },
      get: function() {
        return this.validations.businessId;
      }
    },
    postalIsValid: {
      set: function(val) {
        this.$emit("validate", {
          field: "postal",
          adressType: this.addressType,
          value: val
        });
      },
      get: function() {
        return this.validations.postal;
      }
    },
    zipCodeIsValid: {
      set: function(val) {
        this.$emit("validate", {
          field: "zipCode",
          adressType: this.addressType,
          value: val
        });
      },
      get: function() {
        return this.validations.zipCode;
      }
    },
    countryIsValid: {
      set: function(val) {
        this.$emit("validate", {
          field: "country",
          adressType: this.addressType,
          value: val
        });
      },
      get: function() {
        return this.validations.country;
      }
    }
  }
};
</script>

<style scoped>
.form-section-head {
  display: flex;
  flex-direction: row;
  cursor: pointer;
}
.form-section-head > i {
  margin-left: auto;
  margin-top: 8px;
}
.form-section {
  border: 1px solid black;
}

.form-section:last-child {
}
.form-section > h4 {
}
.hide {
  display: none;
}
input {
  border-radius: 0;
  box-shadow: 1px 1px 1px 1px black;
  height: 1.5em;
}
.custom-datalist-input {
  border-radius: 0;
  box-shadow: none;
  height: auto;
}
.operators {
}
.spinner {
  margin: 200px auto;
  width: 60px;
  height: 40px;
  text-align: center;
  font-size: 20px;
}

.spinner > div {
  background-color: rgb(235, 222, 222);
  height: 100%;
  width: 6px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.loader-wrap {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2;
}
.search-field > input {
  width: 100%;
}
.search-field:focus-within .search-icon {
  display: none;
}
.input-group {
  width: 100%;
}
.search-icon {
  position: absolute;
  right: 5px;
  font-size: 20px;
  color: hsla(0, 0%, 0%, 0.4);
}
.address {
  padding: 1em;
  border: 1px solid gray;
  border-radius: 10px;
}
.customer-mobile-opener {
  display: none;
}
.customer-view {
  height: 100%;
  background: white;
  padding: 1em;
  position: relative;
  border-radius: 15px;
}
.container {
}
.customer-list-number {
  margin-right: 5px;
  color: #76787a;
}
.customer-list-container {
  min-width: 320px;
  margin: 0 15px 0 15px;
}
.customer-list {
  background: white;
  min-height: 400px;
  padding: 0;
  list-style: none;
  max-height: 400px;
  overflow: auto;
  overflow-y: scroll;
}
.customer-list-item {
  padding: 10px 0 10px 10px;
  text-align: left;
  display: flex;
  flex-direction: row;
}
.list-favorite {
  width: 10%;
}
.yellow {
  color: #f5cc27;
}
.gray {
  color: #c8c8c8;
}
.customer-list-item:hover {
  background: rgb(235, 228, 228);
}
.selected-item {
  background: rgb(235, 228, 228);
}

.form-container {
  width: 100px;
}
.buttons {
  /* position: absolute;
  bottom: 15px;
  right: 15px; */
  margin-left: auto;
}
legend {
  color: #76787a;
}
.customer-name {
  font-size: 30px;
}
.item-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.customer-number {
  color: #76787a;
}
.customer-name-wrap {
  width: 85%;
  margin-right: 10px;
  /*   text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; */
}
.back-btn {
  margin-left: 1.1em;
  margin-bottom: 10px;
}
@media screen and (max-width: 769px) {
  .back-btn {
    margin-left: auto;
    margin-right: 10px;
    margin-bottom: 0;
  }
  .buttons {
    margin-top: 10px;
    position: relative;
    bottom: 0;
    right: 0;
  }
  .customer-list {
    position: relative;
    height: 550px;
    max-height: 550px;
  }
  .customer-list-container {
    position: fixed;
    padding: 5px;
    background: #cccccc;
    margin: 0;
    z-index: 1;
    left: -320px;
    top: 5px;
    transition: all 0.5s;
  }
  .customer-view {
    margin-top: 1em;
  }
  .customer-mobile-opener {
    display: block;
    position: absolute;
    padding: 5px;
    right: -42px;
    top: 0px;
    background: #cccccc;
    border-radius: 0 5px 5px 0;
    border-left: 0;
    color: #6c757d;
  }
  .open {
    transform: translateX(100%);
  }
}
@media screen and (max-width: 426px) {
  .form-section {
      border: 1px solid #c8c8c8;
  }
}
@media screen and (max-width: 376px) {
  .form-control {
  border-radius: 0;
  box-shadow: none!important;
}
}
</style>
