<template>
  <div class="container">
    <div class="row">
      <button
        class="btn btn-secondary back-btn"
        @click="$router.push({name:'invoiceBuilder'})"
      >{{ $t("customer.back") }}</button>
    </div>
    <div class="row">
      <div class="customer-list-container" :class="{'open':mobileListOpen}">
        <div @click="mobileListOpen = !mobileListOpen" class="customer-mobile-opener">
          <i class="fas fa-list fa-2x"></i>
        </div>
        <div class="input-group search-field">
          <input
            style="width:100%"
            class="custom-datalist-input"
            v-model.trim="searchText"
            v-bind:placeholder="$t('customer.searchShort')"
          >
          <div class="search-icon">
            <i class="fa fa-search"></i>
          </div>
        </div>
        <ul class="customer-list">
          <li
            @click="selectCustomer(c)"
            v-for="(c, key) in filteredCustomers"
            :key="key"
            class="customer-list-item"
            :class="{'selected-item':originalNumber === c.customerNumber}"
          >
            <div class="customer-list-number">{{c.customerNumber}}</div>
            <div class="customer-name-wrap">
              <div class="item-name">{{c.name}}</div>
            </div>
            <i v-if="c.isFavorite" class="fa fa-star list-favorite yellow"></i>
          </li>
          <div v-if="filteredCustomers.length === 0">{{ $t("product.noResults") }}</div>
        </ul>
      </div>
      <div class="col customer-view">
        <div v-if="loading" class="loader-wrap">
          <div class="spinner">
            <div class="rect1"></div>
            <div class="rect2"></div>
            <div class="rect3"></div>
            <div class="rect4"></div>
            <div class="rect5"></div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h4 class="customer-name">
              <span class="customer-number">{{ customer.customerNumber }}</span>
              {{ customer.billingAddress.name || $t("customer.newCustomer") }}
            </h4>
          </div>
          <i
            @click="favorite"
            style="cursor:pointer"
            :title="$t('customer.setFavorite')"
            v-bind:class="{'fa fa-star fa-2x yellow':customer.isFavorite, 'fa fa-star fa-2x favorite-icon gray':!customer.isFavorite}"
          ></i>
        </div>
        <div class="row">
          <div class="col-md-6">
            <div class="forms">
              <customer-address-form
                :display="display.billingAddress"
                v-on:hide="display.billingAddress = $event"
                v-on:focused="bidFocused = true"
                v-on:unfocused="bidFocused = false"
                :addressType="'billingAddress'"
                :address="customer.billingAddress"
                :validations="validations.billingAddress"
                v-on:validate="validations.billingAddress[$event.field] = $event.value"
                v-on:update="customer.billingAddress[$event.field] = $event.value"
              ></customer-address-form>
              <customer-address-form
                :display="display.officialAddress"
                v-on:hide="display.officialAddress = $event"
                v-on:focused="bidFocused = true"
                v-on:unfocused="bidFocused = false"
                :addressType="'officialAddress'"
                :address="customer.officialAddress"
                :validations="validations.officialAddress"
                v-on:validate="validations.officialAddress[$event.field] = $event.value"
                v-on:update="customer.officialAddress[$event.field] = $event.value"
              ></customer-address-form>
              <customer-address-form
                :display="display.deliveryAddress"
                v-on:hide="display.deliveryAddress = $event"
                v-on:focused="bidFocused = true"
                v-on:unfocused="bidFocused = false"
                :addressType="'deliveryAddress'"
                :address="customer.deliveryAddress"
                :validations="validations.deliveryAddress"
                v-on:validate="validations.deliveryAddress[$event.field] = $event.value"
                v-on:update="customer.deliveryAddress[$event.field] = $event.value"
              ></customer-address-form>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <customer-number-input
                :value="customer.customerNumber"
                :valid="validations.customerNumber"
                v-on:validate="validations.customerNumber = $event"
                v-on:input="customer.customerNumber = $event"
                :orig="originalNumber"
                :token="token"
              />
            </div>
            <div class="form-group col-lg">
              <label class="form-label">{{ $t("customer.eInvoiceAddress") }}</label>
              <input
                maxlength="60"
                class="form-control form-control-sm"
                list="addresses"
                v-model="customer.eInvoiceAddress"
                :placeholder="$t('customer.eInvoiceAddress')"
                v-bind:class="{'':eInvoiceInfoValid === null,
                                  'is-valid':eInvoiceInfoValid === true,
                                  'is-invalid':eInvoiceInfoValid === false}"
              >

              <datalist id="addresses">
                <option v-for="(e, key) in einvoiceAddresses" :key="key">{{e}}</option>
              </datalist>
            </div>

            <div style="position:relative" class="form-group col pl-0 pr-0">
              <label class="form-label">{{ $t("invoiceForm.operator") }}</label>
              <select
                v-model="customer.operator"
                class="form-control form-control-sm operators"
                v-bind:class="{'':eInvoiceInfoValid === null,
                                  'is-valid':eInvoiceInfoValid === true,
                                  'is-invalid':eInvoiceInfoValid === false}"
              >
                <option :value="{name:'',value:''}">{{ $t("invoiceForm.operator")}}</option>
                <option
                  v-for="(o, index) in operators"
                  :key="index"
                  :value="o"
                >{{o.name}}</option>
              </select>


            </div>
            <div class="d-flex align-items-end button-space">
              <div class="buttons">
                <button
                  @click="newCustomer"
                  :disabled="newCust"
                  class="btn btn-success mr-1"
                >{{ $t("customer.create") }}</button>
                <button
                  v-if="!newCust"
                  @click="saveCustomer"
                  class="btn btn-success mr-1"
                >{{ $t("customer.save") }}</button>
                <button
                  v-else
                  @click="addCustomer"
                  class="btn btn-success mr-1"
                >{{ $t("customer.createNew") }}</button>
                <button
                  @click="deleteCustomer"
                  :disabled="newCust"
                  class="btn btn-success"
                >{{ $t("customer.delete") }}</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PurchaseInvoiceFormField from "@/components/purchaseInvoice/PurchaseInvoiceFormField";
import CustomerCountryInput from "@/components/customer/CustomerCountryInput";
import CustomerNumberInput from "@/components/customer/CustomerNumberInput";
import CustomerAddressForm from "@/components/customer/CustomerAddressForm";
import { operators } from "@/assets/operators.js";
import {
  simpleValidation,
  validateYtunnus,
  validateOrgNum,
  validateMinEight
} from "@/utils";
import { codes } from "@/assets/countrycodes";

export default {
  name: "customerRecord",
  mounted: function() {
    this.$store
      .dispatch("customer/getNextFreeCustNum", this.token)
      .then(res => {
        this.customer.customerNumber = res.data;
      });
    this.$store.dispatch("customer/fetchCustomers", this.token).then(res => {
      this.customers = res;
    });
  },
  data() {
    let that = this;
    return {
      display: {
        officialAddress: false,
        billingAddress: true,
        deliveryAddress: false
      },
      bidFocused: false,
      loading: false,
      einvoiceAddresses: [],
      validateYtunnus,
      validateOrgNum,
      validateMinEight,
      searchTimeout: null,
      newCust: true,
      searchText: "",
      mobileListOpen: false,
      addressTypes: ["billingAddress", "officialAddress", "deliveryAddress"],
      simpleValidation,
      customers: [],
      originalNumber: null,
      customer: {
        customerNumber: null,
        isFavorite: false,
        eInvoiceAddress: "",
        operator: { name: "", value: "" },
        businessId: "",
        officialAddress: {
          name: "",
          country: "",
          businessId: "",
          address: "",
          postal: "",
          zipCode: "",
          role: "BUYER"
        },
        billingAddress: {
          name: "",
          country: "",
          businessId: "",
          address: "",
          postal: "",
          zipCode: "",
          role: "PAYER"
        },
        deliveryAddress: {
          name: "",
          country: "",
          address: "",
          businessId: "",
          postal: "",
          zipCode: "",
          role: "DELIVERY"
        }
      },
      defaultCustomer: {
        customerNumber: null,
        isFavorite: false,
        contactPerson: "",
        eInvoiceAddress: "",
        businessId: "",
        officialAddress: {
          name: "",
          country: "",
          businessId: "",
          address: "",
          postal: "",
          zipCode: "",
          role: "BUYER"
        },
        billingAddress: {
          name: "",
          country: "",
          businessId: "",
          address: "",
          postal: "",
          zipCode: "",
          role: "PAYER"
        },
        deliveryAddress: {
          name: "",
          country: "",
          address: "",
          businessId: "",
          postal: "",
          zipCode: "",
          role: "DELIVERY"
        }
      },
      validations: {
        customerNumber: null,
        contactPerson: null,
        eInvoiceAddress: null,
        businessId: null,
        officialAddress: {
          name: null,
          country: null,
          businessId: null,
          address: null,
          postal: null,
          zipCode: null
        },
        billingAddress: {
          name: null,
          country: null,
          businessId: null,
          address: null,
          postal: null,
          zipCode: null
        },
        deliveryAddress: {
          name: null,
          country: null,
          address: null,
          postal: null,
          zipCode: null
        }
      }
    };
  },
  watch: {
    officialAddressEmpty: function(val) {
      if (val) {
         Object.keys(this.validations.officialAddress).forEach(k => {
        this.validations.officialAddress[k] = null
      });
      }
    },
    deliveryAddressEmpty: function(val) {
      if (val) {
         Object.keys(this.validations.deliveryAddress).forEach(k => {
        this.validations.deliveryAddress[k] = null
      });
      }
    },
    eInvoiceAddressAndAddresses: function(val) {
/*       let { addr, addrs } = val;
      if (addr.includes("0007:") && addrs.find(adr => adr === addr)) {
        this.customer.operator = { name: "Peppol-AP", value: "Peppol-AP" };
      } else if (
        this.customer.operator.name === "Peppol-AP" &&
        !addrs.find(adr => adr === addr)
      ) {
        this.customer.operator = { name: "", value: "" };
      } */
    },

    $route(to, from) {
      if (to.name === "customerRecord") {
        this.newCustomer();
        this.$store
          .dispatch("customer/fetchCustomers", this.token)
          .then(res => {
            this.customers = res;
          });
      }
    },
    "customer.officialAddress.businessId": function(val) {
      this.queryYTJ(val, "officialAddress");
    },
    "customer.billingAddress.businessId": function(val) {
      this.queryYTJ(val, "billingAddress");
      if (val.length > 8) {
        this.$store
          .dispatch("customer/checkAddresses", {
            tkey: this.userData.TransferKey,
            tid: this.userData.TransferID,
            bid: val
          })
          .then(data => {
            this.einvoiceAddresses = data.map(a => a.value);
          })
          .catch(error => {
            console.log(error);
            this.einvoiceAddresses = [];
            if (this.userData.entryPoint === "fakturamappen") {
              this.$store
                .dispatch("customer/deliveryMethodQuery", {
                  tkey: this.userData.TransferKey,
                  tid: this.userData.TransferID,
                  receiverBid: val,
                  receiverName: this.customer.billingAddress.name,
                  senderName: this.userData.Name,
                  senderBid: this.userData.Ytunnus
                })
                .then(data => {
                  if (
                    data.find(
                      i => i["@type"] === "ChannelName" && i["$"] === "eInvoice"
                    )
                  ) {
                    this.einvoiceAddresses = ["0007:" + val.replace("-", "")];
                  } else {
                    this.einvoiceAddresses = [];
                  }
                })
                .catch(error => {
                  console.log(error);
                  this.einvoiceAddresses = [];
                });
            }
          });
      } else {
        this.einvoiceAddresses = [];
      }
    }
  },
  methods: {
    favorite: function() {
      if (!this.newCust) {
        let req = {
          favorite: !this.customer.isFavorite,
          customerNumber: Number(this.originalNumber)
        };
        let token = this.token;
        this.$store
          .dispatch("customer/setFavoriteCustomer", { req, token })
          .then(data => {
            this.customer.isFavorite = !this.customer.isFavorite;
            this.customers.find(
              c => c.customerNumber === this.originalNumber
            ).isFavorite = this.customer.isFavorite;
          })
          .catch(error => {
            console.log(error);
          });
      } else {
        this.customer.isFavorite = !this.customer.isFavorite;
      }
    },
    queryYTJ: function(val, addressType) {
      if (this.bidFocused) {
        this.$store
          .dispatch("customer/queryCustomer", val)
          .then(data => {
            if (!data.YritysLopetettu) {
              this.customer[addressType].name = data.Toiminimi;
              this.customer[addressType].businessId = data.YTunnus;
              this.customer[addressType].address =
                data.YrityksenPostiOsoite.Katuosoite;
              this.customer[addressType].zipCode =
                data.YrityksenPostiOsoite.Postinumero;
              this.customer[addressType].postal =
                data.YrityksenPostiOsoite.Toimipaikka;
              this.customer[addressType].country = this.countries.find(
                country => {
                  return country.value === data.YrityksenPostiOsoite.Maakoodi;
                }
              );
            }
          })
          .catch(error => {
            console.log(error);
          });
      }
    },
    selectCustomer: function(c) {
      this.mobileListOpen = false;
      this.loading = true;
      let req = { token: this.token, number: c.customerNumber };
      this.$store
        .dispatch("customer/getCustomer", req)
        .then(response => {
          this.addressTypes.forEach(a => {
            let role =
              a === "officialAddress"
                ? "BUYER"
                : a === "billingAddress"
                ? "PAYER"
                : "DELIVERY";
            let data =
              response.addresses.find(ad => ad.role === role) ||
              this.defaultCustomer[a];
            this.customer[a].name = data.name;
            this.customer[a].address = data.address;
            this.customer[a].postal = data.postal;
            this.customer[a].zipCode = data.zipCode;

            this.display[a] = data.name.length > 0;
            if (role !== "DELIVERY") {
              this.customer[a].businessId = data.businessId;
            }
            if (data.country) {
              this.customer[a].country = this.countries.find(
                country =>
                  country.value.toLowerCase() === data.country.toLowerCase()
              );
            } else {
              this.customer[a].country = "";
            }
          });
          this.customer.eInvoiceAddress = response.eInvoiceAddress;
          this.customer.customerNumber = response.customerNumber;
          this.customer.operator = this.operators.find(
            o => o.name === response.operator
          ) || { name: "", value: "" };
          this.customer.isFavorite = response.isFavorite !== null;
          this.loading = false;
          this.newCust = false;
          this.originalNumber = response.customerNumber;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },
    newCustomer: function() {
      this.loading = true;
      this.$store
        .dispatch("customer/getNextFreeCustNum", this.token)
        .then(res => {
          this.customer.customerNumber = res.data;
          this.originalNumber = res.data;
          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
      this.addressTypes.forEach(a => {
        let role =
          a === "officialAddress"
            ? "BUYER"
            : a === "billingAddress"
            ? "PAYER"
            : "DELIVERY";
        this.customer[a].name = "";
        this.customer[a].address = "";
        this.customer[a].postal = "";
        this.customer[a].zipCode = "";
        if (role !== "DELIVERY") {
          this.customer[a].businessId = "";
        }
        this.customer[a].country = "";
      });
      this.customer.officialAddress.name = "";
      this.customer.eInvoiceAddress = "";
      this.customer.operator = { name: "", value: "" };
      this.customer.isFavorite = 0;
      this.newCust = true;
    },
    saveCustomer: function() {
      let valid = this.validateAddresses();
      if (
        !valid ||
        this.eInvoiceInfoValid === false ||
        !this.validations.customerNumber
      ) {
        this.validations.customerNumber = !this.validations.customerNumber
          ? false
          : true;
        return;
      }
      let token = this.token;
      this.loading = true;
      let request = {
        originalNumber: Number(this.originalNumber),
        customerNumber: Number(this.customer.customerNumber),
        isFavorite: this.customer.isFavorite ? 1 : 0,
        eInvoiceAddress: this.customer.eInvoiceAddress,
        operator: this.customer.operator.name, //this.customer.operator.name ? (this.customer.operator.name === "Peppol-AP" ? "" : this.customer.operator.name) : "",
        addresses: []
      };

      if (this.billingAddressValid) {
        request.addresses.push(
          JSON.parse(JSON.stringify(this.customer.billingAddress))
        );
      } else {
        return;
      }
      if (this.officialAddressValid) {
        request.addresses.push(
          JSON.parse(JSON.stringify(this.customer.officialAddress))
        );
      }
      if (this.deliveryAddressValid) {
        request.addresses.push(
          JSON.parse(JSON.stringify(this.customer.deliveryAddress))
        );
      }
      request.addresses.forEach(a => {
        if (a.country) {
          a.country = a.country.value;
        }
      });
      this.$store
        .dispatch("customer/updateCustomer", { req: request, token })
        .then(data => {
          let cust = this.customers.find(
            c => c.customerNumber === this.originalNumber
          );
          cust.name = this.customer.billingAddress.name;
          cust.customerNumber = this.customer.customerNumber;
          this.originalNumber = cust.customerNumber;

          this.loading = false;
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },
    deleteCustomer: function() {
      this.$store
        .dispatch("customer/deleteCustomer", {
          token: this.token,
          customer: this.originalNumber
        })
        .then(data => {
          let pos = this.filteredCustomers
            .map(function(e) {
              return e.customerNumber;
            })
            .indexOf(this.originalNumber);
          this.customers = this.customers.filter(
            c => c.customerNumber !== this.originalNumber
          );

          if (this.filteredCustomers.length === 0) {
            this.newCustomer();
          } else if (pos === this.filteredCustomers.length) {
            this.selectCustomer(this.filteredCustomers[pos-1]);
          } else {
            this.selectCustomer(this.filteredCustomers[pos]);
          }
        });
    },
    addCustomer: function() {
      let valid = this.validateAddresses();
      if (
        !valid ||
        this.eInvoiceInfoValid === false ||
        !this.validations.customerNumber
      ) {
        this.validations.customerNumber = !this.validations.customerNumber
          ? false
          : true;
        return;
      }

      let token = this.token;
      this.loading = true;
      let request = {
        customerNumber: Number(this.customer.customerNumber),
        eInvoiceAddress: this.customer.eInvoiceAddress,
        operator: this.customer.operator.name, // ? (this.customer.operator.name === "Peppol-AP" ? "" : this.customer.operator.name) : "",
        isFavorite: this.customer.isFavorite ? 1 : 0,
        addresses: []
      };

      if (this.billingAddressValid) {
        request.addresses.push(
          JSON.parse(JSON.stringify(this.customer.billingAddress))
        );
      } else {
        return;
      }
      if (this.officialAddressValid) {
        request.addresses.push(
          JSON.parse(JSON.stringify(this.customer.officialAddress))
        );
      } /* else if (this.officialAddressValid === null) {
        this.display.officialAddress = true;
        let copy = Object.assign({}, this.customer.billingAddress);
        copy.role = "BUYER";
        this.customer.officialAddress = Object.assign(
          this.customer.officialAddress,
          copy
        );
        request.addresses.push(JSON.parse(JSON.stringify(copy)));
      } */
      if (this.deliveryAddressValid) {
        request.addresses.push(
          JSON.parse(JSON.stringify(this.customer.deliveryAddress))
        );
      }
      request.addresses.forEach(a => {
        if (a.country) {
          a.country = a.country.value;
        }
      });

      this.$store
        .dispatch("customer/addCustomer", { req: request, token })
        .then(data => {
          this.loading = false;
          let c = {
            customerNumber: data.data,
            isFavorite: this.customer.isFavorite,
            name: this.customer.billingAddress.name
          };
          this.customers.push(c);
          this.customer.customerNumber = data.data;
          this.originalNumber = data.data;
          this.newCust = false;
          // this.newCustomer()
        })
        .catch(error => {
          console.log(error);
          this.loading = false;
        });
    },
    validateAddresses: function() {
      let allValid = true;
      Object.keys(this.validations.billingAddress).forEach(k => {
        if (
          this.validations.billingAddress[k] === false ||
          this.validations.billingAddress[k] === null
        ) {
          this.validations.billingAddress[k] = false;
          allValid = false;
          this.display.billingAddress = true;
        }
      });

      let allNull = true;
      Object.keys(this.validations.officialAddress).forEach(k => {
        if (this.validations.officialAddress[k] !== null) {
          allNull = false;
        }
      });
      if (!allNull) {
        Object.keys(this.validations.officialAddress).forEach(k => {
          if (this.validations.officialAddress[k] === null) {
            this.validations.officialAddress[k] = false;
            allValid = false;
            this.display.officialAddress = true;
          }
        });
      }

      allNull = true;
      Object.keys(this.validations.deliveryAddress).forEach(k => {
        if (this.validations.deliveryAddress[k] !== null) {
          allNull = false;
        }
      });
      if (!allNull) {
        Object.keys(this.validations.deliveryAddress).forEach(k => {
          if (this.validations.deliveryAddress[k] === null) {
            this.validations.deliveryAddress[k] = false;
            allValid = false;
            this.display.officialAddress = true;
          }
        });
      }
      return allValid;
    }
  },
  computed: {
    operators: function() {
      return this.userData.entryPoint === "laskumappi" ? operators.fi : operators.se
    },
    eInvoiceAddressAndAddresses: function() {
      return {
        addr: this.customer.eInvoiceAddress,
        addrs: this.einvoiceAddresses
      };
    },
    eInvoiceInfoValid: function() {
      if (
        (this.customer.eInvoiceAddress && !this.customer.operator.name) ||
        (!this.customer.eInvoiceAddress && this.customer.operator.name)
      ) {
        return false;
      } else if (
        !this.customer.eInvoiceAddress &&
        !this.customer.operator.name
      ) {
        return null;
      } else {
        return true;
      }
    },
    countries: function() {
      return codes[this.$store.getters["invoice/getField"]("userData.locale")];
    },
    filteredCustomers: function() {
      let customers = this.customers.filter(c => {
        return (
          c.name.toLowerCase().includes(this.searchText.toLowerCase()) ||
          c.customerNumber.toString().includes(this.searchText)
        );
      });
      return customers.sort(function(a, b) {
        let favorite = 0;
        let num = 0;
        if (a.isFavorite && !b.isFavorite) {
          favorite = -1;
        } else if (!a.isFavorite && b.isFavorite) {
          favorite = 1;
        }

        if (a.customerNumber < b.customerNumber) {
          num = -1;
        } else if (a.customerNumber > b.customerNumber) {
          num = 1;
        }

        if (favorite === 1) {
          return 1;
        } else if (favorite === -1) {
          return -1;
        } else if (favorite === 0 && num === 1) {
          return 1;
        } else if (favorite === 0 && num === -1) {
          return -1;
        } else if (favorite === 0 && num === 0) {
          return 0;
        } else {
          return 0;
        }
      });
    },
    token: function() {
      return this.$store.getters["invoice/getField"]("userData.authToken");
    },
    userData: function() {
      return this.$store.getters["invoice/getField"]("userData");
    },
    billingAddressValid: function() {
      let allNull = true;
      let allTrue = true;
      let keys = Object.keys(this.validations.billingAddress);

      keys.forEach(k => {
        if (this.validations.billingAddress[k] === false) {
          allTrue = false;
        } else if (this.validations.billingAddress[k] === true) {
          allNull = false;
        } else if (!allNull && this.validations.billingAddress[k] === null) {
          allTrue = false;
        }
      });
      return allNull ? null : true;
    },
    officialAddressValid: function() {
      let allNull = true;
      Object.keys(this.validations.officialAddress).forEach(k => {
        if (this.validations.officialAddress[k] === false) {
          return false;
        }
        if (this.validations.officialAddress[k] === true) {
          allNull = false;
        }
      });
      return allNull ? null : true;
    },
    deliveryAddressValid: function() {
      let allNull = true;
      Object.keys(this.validations.deliveryAddress).forEach(k => {
        if (this.validations.deliveryAddress[k] === false) {
          return false;
        }
        if (this.validations.deliveryAddress[k] === true) {
          allNull = false;
        }
      });
      return allNull ? null : true;
    },
  deliveryAddressEmpty: function() {
    let empty = true
    Object.keys(this.customer.deliveryAddress).forEach(k => {
      if (this.customer.deliveryAddress[k] !== "" && k !== "role") {
        empty = false
      }
    })
    return empty
  },
  officialAddressEmpty: function() {
    let empty = true
    Object.keys(this.customer.officialAddress).forEach(k => {
      if (this.customer.officialAddress[k] !== "" && k !== "role") {
        empty = false
      }
    })
    return empty
  },
  },
  components: {
    PurchaseInvoiceFormField,
    CustomerCountryInput,
    CustomerNumberInput,
    CustomerAddressForm
  }
};
</script>

<style scoped>
.forms {
  z-index: 1;
  position: relative;
}
.forms:before {
  content: "";
  display: inline-block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  box-shadow: 5px 5px 5px 0px #888;
}
.hide {
  display: none;
}
input {
  border-radius: 0;
  box-shadow: 1px 1px 1px 1px black;
  height: 1.5em;
}
.custom-datalist-input {
  border-radius: 0;
  box-shadow: none;
  height: auto;
}
.operators {
}
.spinner {
  margin: 200px auto;
  width: 60px;
  height: 40px;
  text-align: center;
  font-size: 20px;
}

.spinner > div {
  background-color: rgb(235, 222, 222);
  height: 100%;
  width: 6px;
  display: inline-block;

  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}

.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
  }
}

@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

.loader-wrap {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 15px;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 2;
}
.search-field > input {
  width: 100%;
}
.search-field:focus-within .search-icon {
  display: none;
}
.input-group {
  width: 100%;
}
.search-icon {
  position: absolute;
  right: 5px;
  font-size: 20px;
  color: hsla(0, 0%, 0%, 0.4);
}
.address {
  padding: 1em;
  border: 1px solid gray;
  border-radius: 10px;
}
.customer-mobile-opener {
  display: none;
}
.customer-view {
  height: 100%;
  background: white;
  padding: 1em;
  position: relative;
  border-radius: 15px;
}
.container {
}
.customer-list-number {
  margin-right: 5px;
  color: #76787a;
}
.customer-list-container {
  min-width: 320px;
  margin: 0 15px 0 15px;
}
.customer-list {
  background: white;
  min-height: 400px;
  padding: 0;
  list-style: none;
  max-height: 400px;
  overflow: auto;
  overflow-y: scroll;
}
.customer-list-item {
  padding: 10px 0 10px 10px;
  text-align: left;
  display: flex;
  flex-direction: row;
}
.list-favorite {
  width: 10%;
}
.yellow {
  color: #f5cc27;
}
.gray {
  color: #c8c8c8;
}
.customer-list-item:hover {
  background: rgb(235, 228, 228);
}
.selected-item {
  background: rgb(235, 228, 228);
}

.form-container {
  width: 100px;
}
.buttons {
  /* position: absolute;
  bottom: 15px;
  right: 15px; */
  margin-left: auto;
}
.button-space {
  height: calc(100% - 160px);
}
legend {
  color: #76787a;
}
.customer-name {
  font-size: 30px;
}
.item-name {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.customer-number {
  color: #76787a;
}
.customer-name-wrap {
  width: 85%;
  margin-right: 10px;
  /*   text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; */
}
.back-btn {
  margin-left: 1.1em;
  margin-bottom: 10px;
}
@media screen and (max-width: 769px) {
  .back-btn {
    margin-left: auto;
    margin-right: 10px;
    margin-bottom: 0;
  }
  .buttons {
    margin-top: 10px;
    position: relative;
    bottom: 0;
    right: 0;
  }
  .customer-list {
    position: relative;
    height: 550px;
    max-height: 550px;
  }
  .customer-list-container {
    position: fixed;
    padding: 5px;
    background: #cccccc;
    margin: 0;
    z-index: 2;
    left: -320px;
    top: 5px;
    transition: all 0.5s;
  }
  .customer-view {
    margin-top: 1em;
  }
  .customer-mobile-opener {
    display: block;
    position: absolute;
    padding: 5px;
    right: -42px;
    top: 0px;
    background: #cccccc9f;
    border-radius: 0 5px 5px 0;
    border-left: 0;
    color: #5d686e;
  }
  .open {
    transform: translateX(100%);
  }
}
@media screen and (max-width: 426px) {
  .forms {
    margin-bottom: 10px;
  }
  .forms:before {
    box-shadow: none;
  }
}

@media screen and (max-width: 376px) {
  .form-control {
    border-radius: 0;
    box-shadow: none !important;
  }
  .button-space {
    height: 50px;
  }
}
</style>
