<template>
  <div class="form-group col-lg">
    <label class="form-label">{{ $t("invoiceForm.country") }}</label>
    <input
      :list="'countries-'+addrType"
      class="form-control form-control-sm"
      v-model.trim="input"
      v-bind:placeholder="$t('invoiceForm.country')"
      v-bind:class="{'':valid === null,
                      'is-valid':valid === true,
                      'is-invalid':valid === false,
                      }"
    >
    <datalist :id="'countries-'+addrType">
      <option v-for="(country, index) in dataList" :key="index" :value="country.name"></option>
    </datalist>

  </div>
</template>

<script>
export default {
  name: "CustomerCountryInput",
  props: ["valid", "fix", "country", "countries", "addrType"],
  computed: {
    dataList: function() {
      if (this.input === "" || this.input.name === "") {
        return [];
      }
      let count = 0;
      return this.countries.filter(country => {
        return (
          country.name.toLowerCase().startsWith(this.input.toLowerCase()) &&
          count++ < 15
        );
      });
    },
    isValid: function() {
      let value = this.input;
      let country = this.countries.find(
        country => country.name.toLowerCase() === value.toLowerCase()
      );
      if (value && country) {
        return true;
      } else if (value) {
        return false;
      } else {
        return null;
      }
    },
    input: {
      get() {
        let value = this.country === null ? "" : this.country;
        value =
          this.countries.find(
            country =>
              country.name.toLowerCase() ===
              (typeof value === "object" ? value.name : value).toLowerCase()
          ) || value;
        return typeof value === "object" ? value.name : value;
      },
      set(value) {
        value =
          this.countries.find(
            country => country.name.toLowerCase() === value.toLowerCase()
          ) || value;
        this.$emit("country", value);
      }
    }
  },
  watch: {
    input: {
      handler: function(value) {
        let country = this.countries.find(
          country => country.name.toLowerCase() === value.toLowerCase()
        );
        if (value && country) {
          this.$emit("validate", true);
        } else if (value) {
          this.$emit("validate", false);
        } else {
          this.$emit("validate", null);
        }
      },
      deep: true,
      immediate: true
    },
    isValid: function(value) {
      this.$emit("validate", value);
    },
    //Fix for validating
    addrType: function(s) {
      let value = this.input;
      let country = this.countries.find(
        country => country.name.toLowerCase() === value.toLowerCase()
      );
      if (value && country) {
        this.$emit("validate", true);
      } else if (value) {
        this.$emit("validate", false);
      } else {
        this.$emit("validate", null);
      }
    }
  }
};
</script>

<style scoped>
.form-control-sm {
  padding: 0 2em 0 0.5em;
  height: 1.5em;
}

.no-validation {
  padding: 0 0.5em 0 0.5em;
}
.form-group {
  margin-bottom: 0.5rem;
}

label {
  text-align: left;
  margin-bottom: 0.2rem;
}

input {
  border-radius: 0;
  box-shadow: 1px 1px 1px 1px black;
  height: 1.5em;
}

input:focus {
  border-radius: 0;
}


@media screen and (max-width: 425px) {
  input {
    width: 100%;
    margin-bottom: 1em;
    box-shadow: none;
  }

  input:focus {
    border-radius: 0;
  }

  .row {
    padding: 0;
  }

  .form-group {
    margin: 0;
  }

  .date-field > label {
    display: inline;
  }
  .date-field > input {
    display: inline;
    width: 60%;
  }
}
</style>
